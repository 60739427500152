






















































import ShowMnemonicTs from './ShowMnemonicTs';
export default class ShowMnemonic extends ShowMnemonicTs {}
