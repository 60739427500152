













import { MnemonicDisplayTs } from './MnemonicDisplayTs';
export default class MnemonicDisplay extends MnemonicDisplayTs {}
