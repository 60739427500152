/*
 * (C) Symbol Contributors 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and limitations under the License.
 *
 */
import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';

// @ts-ignore
import ButtonCopyToClipboard from '@/components/ButtonCopyToClipboard/ButtonCopyToClipboard.vue';
import { Formatters } from '@/core/utils/Formatters';

@Component({
    components: { draggable, ButtonCopyToClipboard },
})
export class MnemonicDisplayTs extends Vue {
    @Prop({
        default: [],
    })
    words: string[];
    public get waitingCopyString(): string {
        return Formatters.splitArrayByDelimiter(this.words);
    }
}
